@use "./sass/utilities" as utils;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  height: 100vh;
}

//Landing
.LandingPage {
  width: inherit;
  height: inherit;
  @include utils.flex-display($align: flex-start, $justify: unset);
  background-color: rgba(249, 247, 244, 0.767);
  section:nth-child(1) {
    height: inherit;
    width: 60%;
    @include utils.flex-display($align: flex-start);
    .left-card-container-inner {
      padding-top: 60px;
      height: inherit;
      width: 55%;
      @include utils.flex-display(
        $direction: column,
        $align: flex-start,
        $justify: unset
      );
      gap: 70px;

      & > img {
        width: 40px;
        height: 40px;
      }

      > div {
        height: 65%;

        @include utils.flex-display(
          $direction: column,
          $align: flex-start,
          $justify: space-evenly
        );
        h1 {
          @extend %x-large-text;
          @include utils.line-length($max-width: 65rem, $line-height: 60px);
        }
        p {
          @include utils.lead-texts();
          @include utils.line-length($letter-spacing: unset, $max-width: 23rem);
          word-spacing: 2px;
        }
        .name-field {
          position: relative;
          input {
            width: 270px;
            height: 50px;
            border-radius: 35px;
            outline: none;
            padding-left: 20px;
            border-color: utils.$text-primary;
            &::placeholder {
              font-size: 12px;
              color: #17172dec;
            }
          }
          .icon {
            position: absolute;
            right: 27px;
            top: 15px;
            color: utils.$lead-text;
          }
        }
        span {
          color: rgba(167, 35, 35, 0.93);
          padding: 5px 0px 0px 20px;
          font-size: 11px;
        }
      }
    }
  }

  section:nth-child(2) {
    width: 40%;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
}

#hide {
  display: none;
}

#show {
  display: block;
}

#move-arrow {
  transition: all 0.6s ease;
  transform: translateX(12px);
  cursor: pointer;
}

//Main
.Main {
  width: 100%;
  height: 100vh;
  // padding-top: 100px;
  @include utils.flex-display($align: unset);
  background-color: rgba(249, 247, 244, 0.767);

  div {
    width: 40%;
    height: 500px;
    margin-top: 130px;
    @include utils.flex-display($direction: column, $justify: space-around);

    #section-one {
      @include utils.flex-display($direction: column, $justify: unset);
      gap: 5px;
      h1 {
        font-size: 2rem;
        color: utils.$text-primary;
        text-align: center;
      }

      h4 {
        text-align: center;
        font-size: 12px;
        color: #17172dad;
      }
    }

    #section-two {
      display: flex;
      flex-direction: column;
      gap: 40px;
      textarea {
        width: 400px;
        height: 200px;
        transition: all 1s ease;
        max-width: 430px;
        max-height: 230px;
        border: unset;
        font-size: 17px;
        line-height: 23px;
        box-shadow: 2px 1px 9px 1px #aaaab6ad;
        color: utils.$text-primary;
        padding-left: 5px;
        border-radius: 3px;
        position: relative;
        &:focus {
          border: none;
          outline: none;
        }
      }

      #disabled {
        transition: all 1s ease;
        background-color: #ecedf1b3;
        pointer-events: none;
      }

      .buttons-section {
        @include utils.flex-display($justify: space-evenly);
        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          cursor: pointer;
          background-color: white;
          transition: all 1s ease;
          border: unset;
          box-shadow: 2px 1px 9px 1px #aaaab6ad;
          color: utils.$lead-text;

          &:hover {
            background-color: rgba(249, 247, 244, 0.767);
          }
        }
      }
    }
    .buttons-section-two {
      width: 100%;
      @include utils.flex-display($direction: column);
      gap: 10px;
      p {
        font-size: 20px;
        color: #17172dad;
      }
      input {
        height: 25px;
        text-align: center;
        width: 60px;
        border: unset;
        border-radius: 5px;
        box-shadow: 2px 1px 9px 1px #aaaab6ad;
        &:focus {
          outline: none;
        }
      }
    }
  }

  aside {
    background-color: rgba(245, 239, 230, 0.767);
    box-shadow: 2px 1px 9px 1px #aaaab6ad;
    width: 230px;
    height: 100vh;
    position: fixed;
    right: 0;
    overflow: scroll;
    @include utils.flex-display($direction: column, $justify: unset);
    gap: 25px;
    transition: all 0.5s ease;
    h3 {
      padding: 10px;
      text-align: right;
      top: 0;
      background-color: rgb(245, 239, 230);
      z-index: 2;
      position: sticky;
      width: 100%;
      color: utils.$text-primary;
    }

    .selected {
      background-color: slategrey;
    }

    span {
      color: utils.$text-primary;
      text-align: right;
      width: 100%;
      font-size: 18px;
      padding: 3px;
      padding-right: 10px;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        background-color: rgba(232, 222, 208, 0.767);
      }
    }
  }
  span {
    left: 0;
    bottom: 0;
    position: relative;
    cursor: pointer;
    img {
      right: 6px;
      bottom: 10px;
      position: fixed;
      width: 30px;
      height: 30px;
    }
  }
  .coloured {
    background-color: rgb(230, 239, 178) !important;
  }
}

.collapse {
  transition: 0.5s ease !important;
  transform: translateX(300px) !important;
}

//Responsive View
@media screen and (max-width: 1240px) {
  .LandingPage section:nth-child(1) .left-card-container-inner > div h1 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .LandingPage section:nth-child(1) .left-card-container-inner > div h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 924px) {
  .LandingPage section:nth-child(1) .left-card-container-inner > div h1 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 768px) {
  .LandingPage {
    @include utils.flex-display(
      $align: unset,
      $justify: unset,
      $direction: column-reverse
    );
    section:nth-child(1) {
      width: 100%;
      h1 {
        width: 100%;
        text-align: start;
        display: unset !important;
        -webkit-line-clamp: unset !important;
        -webkit-box-orient: unset !important;
        overflow: unset !important;
        line-height: 50px !important;
        letter-spacing: 8px !important;
        font-size: 2rem !important;
      }
      p {
        width: auto !important;
      }
    }
  }
  Main {
    div {
      #section-one {
        h1 {
          font-size: 1.6rem !important;
        }

        h4 {
          font-size: 11px !important;
        }
      }

      #section-two {
        textarea {
          align-self: center !important;
          width: 190% !important;
          font-size: 15px !important;
        }

        .buttons-section {
          justify-content: space-between !important;
          button {
            width: 45px !important;
            height: 45px !important;
          }
        }
      }
      .buttons-section-two {
        p {
          font-size: 17px !important;
        }
        input {
          height: 22px !important;
          width: 65px !important;
        }
      }
    }
    aside {
      width: 190px !important;
      gap: 10px !important;
      h3 {
        font-size: 15px !important;
      }

      .selected {
        background-color: slategrey;
      }

      span {
        font-size: 14px !important;
        padding: 2px !important;
        padding-right: 6px !important;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .LandingPage {
    width: inherit;
    height: inherit;
    @include utils.flex-display($align: center, $justify: unset);
    gap: 0px;
    width: 100% !important;
    section:nth-child(1) {
      width: 60% !important;
      justify-content: center !important;
      .left-card-container-inner {
        padding-left: unset;
      }
      h1 {
        line-height: 40px !important;
        letter-spacing: 8px !important;
        font-size: 1.6rem !important;
      }
      p {
        display: unset !important;
        -webkit-line-clamp: 4 !important;
        -webkit-box-orient: unset !important;
        overflow: unset !important;
        width: auto !important;
        font-size: 13px !important;
      }
      .name-field {
        input {
          height: 45px !important;
          &::placeholder {
            font-size: 11px !important;
          }
        }
        .icon {
          top: 12px !important;
          width: 18px !important;
          height: 18px !important;
        }
      }
    }
    section:nth-child(2) {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  Main {
    div {
      #section-one {
        h1 {
          width: 150% !important;
        }

        h4 {
          width: 150% !important;
        }
      }

      #section-two {
        textarea {
          width: 210% !important;
        }

        .buttons-section {
          width: 155% !important;
          align-self: center !important;
          button {
            width: 35px !important;
            height: 35px !important;
          }
        }
      }
      .buttons-section-two {
        width: 200% !important;
        p {
          font-size: 17px !important;
        }
        input {
          height: 22px !important;
          width: 65px !important;
        }
      }
    }
  }
}
