//Text Colors
$text-primary: #17172dec;
$lead-text: #333239;
%x-large-text {
  font-size: 2.5rem;
  color: $lead-text;
}

//Displays
@mixin flex-display(
  $justify: center,
  $align: center,
  $flex: flex,
  $direction: row
) {
  display: $flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin lead-texts($color: $text-primary) {
  color: $color;
  font-size: 14px;
}

@mixin line-length(
  $length: 3,
  $max-width: 20rem,
  $line-height: 20px,
  $letter-spacing: 15px
) {
  max-width: $max-width;
  display: -webkit-box;
  -webkit-line-clamp: $length;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}
